<template>
  <div class="image-cropper">
    <div class="modal-content-box relative" style="z-index: 2002;">
      <div class="wrapper">
        <div :class="['title', 'color-333', 'ft-28', 'flex', 'flex-ac', 'flex-jc', 'ft-bold']">裁剪</div>
        <div class="cropper-content">
          <div class="avatar-crop">
            <vueCropper
              class="crop-box"
              ref="cropper"
              :img="options.img"
              :autoCrop="options.autoCrop"
              :fixedBox="options.fixedBox"
              :canMoveBox="options.canMoveBox"
              :autoCropWidth="options.autoCropWidth"
              :autoCropHeight="options.autoCropHeight"
              :centerBox="options.centerBox"
              :fixed="options.fixed"
              :fixedNumber="options.fixedNumber"
              :canMove="options.canMove"
              :canScale="options.canScale"
              :enlarge="options.enlarge"
              :outputType="options.outputType"
              :high="options.high"
            ></vueCropper>
          </div>
        </div>
      </div>
      <div style="height: 11rem;"></div>
			<!-- 确认/取消 -->
			<div class="btns flex flex-jc">
				<button class="cancel" @click="closeCropperDialog">取消</button>
				<button class="confirm " @click="confirmCropperDialog">确认</button>
			</div>
    </div>
  </div>
</template>

<script>
import { VueCropper } from 'vue-cropper'
export default {
  components: {
    VueCropper
  },
  props: {
    imgObj: {
      type: Object,
      default: {},
    }
  },
  data() {
    return {
      // vueCropper组件 裁剪配置信息
      options: {
        img: '', // 原图文件
        outputType: 'jpeg', // 裁剪生成图片的格式
        autoCrop: true, // 默认生成截图框
        fixedBox: false, // 固定截图框大小
        canMoveBox: true, // 截图框可以拖动
        autoCropWidth: 300, // 截图框宽度
        autoCropHeight: 300, // 截图框高度
        fixed: true, // 截图框宽高固定比例
        fixedNumber: [1, 1], // 截图框的宽高比例
        centerBox: true, // 截图框被限制在图片里面
        canMove: false, // 上传图片不允许拖动
        canScale: false, // 上传图片不允许滚轮缩放
        enlarge: 1, // 图片根据截图框输出比例倍数，有BUG，1的时候也是2倍
        high: false, // 是否按照设备的dpr输出等比例图片
      }
    }
  },
  computed: {
    fontSize() {
      return document.documentElement.style.fontSize || 0;
    },
  },
  mounted() {
    this.options.img = this.imgObj.content || '';
    let imgWidth = this.fontSize ? parseFloat(this.fontSize).toFixed(2) * 100 : 200;
    this.options.autoCropWidth = imgWidth;
    this.options.autoCropHeight = imgWidth;
  },
  methods: {
    // 关闭弹框
    closeCropperDialog() {
      this.$emit('closeCropperDialog');
      this.options.img = ''
    },
    confirmCropperDialog() {
      this.$refs.cropper.getCropBlob(data => {
        // console.log('data.size', data.size, 'this.imgObj.size', this.imgObj.file.size);
        const file = data.size > this.imgObj.file.size ? this.imgObj.file : data;
        this.$emit('confirmCropperDialog', { file: file });
      })
    },
  }
}
</script>

<style lang="less" scoped>
.image-cropper {
  text-align: left;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  .modal-content-box {
		width: 82rem;
		max-height: 70vh;
		height: fit-content;
		border-radius: 0.8rem;
		background-color: #fff;
	}
  .wrapper {
		position: relative;
	
		.title {
			padding-top: 2.5rem;
			padding-bottom: 2.5rem;
			background: #F2F2F2;
		}
	}

  .btns {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 8.5rem;
		.confirm,
		.cancel {
			color: #fff;
			min-width: 20rem;
			height: 6.5rem;
			border-radius: 0.8rem;
			font-size: 2.4rem;
			padding: 0 2.5rem;
		}
		.cancel {
			background: #EAEAEA;
			color: #666666;
			margin-right: 10rem;
		}
		.confirm {
			background: #1588f5;
		}
	}

  .cropper-content {
    overflow-y: scroll;
    height: 45rem;
  }
  .avatar-crop {
    width: 45rem;
    height: 45rem;
    position: relative;
    margin: 0 auto;
    .crop-box {
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
  }
}
</style>